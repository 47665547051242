'use strict';

Gri.module({
  name: 'filter-alpha',
  ieVersion: null,
  $el: $('.filter-alpha'),
  container: '.filter-alpha',
  fn: function () {
    /*
    var app = new Vue({

      el: '#app',
      data: {
        type: 1,
        il: [],
        selectedIl: [],
        selectedIlceler: [],
        ilceler: [],
        result: []

      },
      methods: {
        findDealer: function() {
          var type = this.type
          var city = this.selectedIl.plaka
          var area = this.selectedIlceler
          this.$http.get(`/json/DealersSearch?city=${city}&area=${area}&type=${type}`).then(response => {
            this.result =[].concat(JSON.parse(response.body));
            console.log(this.result)
            //$('.select-alpha').find('select').selectpicker('refresh');
            this.refreshSelect();
          }, response => {
            alert('data-error');
          });
        },
        getcitys: function () {
          this.$http.get('/ilce.json').then(response => {
            this.il = (response.body);
            //$('.select-alpha').find('select').selectpicker('refresh');
            this.refreshSelect();
          }, response => {
            alert('data-error');
          });
        },
        setcounty: function () {
          this.ilceler = this.selectedIl.ilceleri
          this.refreshSelect();
        },
        refreshSelect: function() {
          let $selectDOM = $('.select-alpha').find('select');
          setTimeout(function () {
            $selectDOM.selectpicker('refresh');
          }, 300)
        }
      }
    })
    app.getcitys();
    */
  }
});
